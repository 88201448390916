import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";

class DateArchivePage extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges;
    const title = this.props.data.site.siteMetadata.title;

    return (
      <Layout>
        <section className="section">
          <Helmet title={`${this.props.pageContext.name} | ${title}`} />
          <div className="container content">
            <div className="columns">
              <div
                className="column is-10 is-offset-1"
                style={{ marginBottom: "6rem" }}
              >
                <h3>Date Archives: {this.props.pageContext.name}</h3>
                {posts.map((post) => (
                  <div className="entry" key={post.node.fields.slug}>
                    <h2 className="entry-title">
                      <Link to={post.node.fields.slug}>
                        {post.node.frontmatter.title}
                      </Link>
                    </h2>
                    <div className="entry-meta">
                      Posted on{" "}
                      <Link to={post.node.fields.slug}>
                        {post.node.frontmatter.date}
                      </Link>
                    </div>
                    <p>
                      {post.node.excerpt}{" "}
                      <Link to={post.node.fields.slug}>Continue reading →</Link>
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default DateArchivePage;

export const datePageQuery = graphql`
  query DatePage($startDate: Date, $endDate: Date) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { date: { gte: $startDate, lte: $endDate } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
